<template>
  <div class="pt-10">
    <div class="vx-col w-auto">
      <vx-card>
        <div class="vx-breadcrumb md:block hidden">
          <ul class="flex flex-wrap items-center">
            <li class="inline-flex items-center">
            
              <a @click="$router.go(-1)"> Manage</a>
              <span class="breadcrumb-separator mx-2">
                <span class="feather-icon select-none relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevrons-right"
                  >
                    <polyline points="13 17 18 12 13 7" />
                  </svg>
                </span>
              </span>
            </li>
            <li class="inline-flex items-center">
              <router-link
                :to="{
                  name: 'director-learning-centre-view-list',
                  params: { type: 'children', id: learningCenterId }
                }"
                >Children
              </router-link>
              <span class="breadcrumb-separator mx-2">
                <span class="feather-icon select-none relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevrons-right"
                  >
                    <polyline points="13 17 18 12 13 7" />
                  </svg>
                </span>
              </span>
            </li>

            <li class="inline-flex items-center">
              <a href="javascript:void(0)" class>Add New Child</a>
            </li>
          </ul>
        </div>

        <div class="mt-6 flex items-center justify-between">
          <h2>Add New Child</h2>
        </div>
        <div class="w-full md:w-1/2">
          <div class="vx-col w-full vs-con-loading__container">
            <template v-if="logo">
              <!-- Image Container -->
              <div class="img-container w-64">
                <img :src="logo" alt="img" class="responsive" />
              </div>

              <!-- Image upload Buttons -->
              <div class="modify-img flex my-8">
                <input
                  type="file"
                  class="hidden"
                  ref="updateImgInput"
                  @change="updateCurrImg"
                  accept="image/*"
                />
                <vs-button
                  type="border"
                  class="mr-4"
                  @click="$refs.updateImgInput.click()"
                  >Update Photo
                </vs-button>
                <vs-button color="danger" @click="logo = null"
                  >Remove Photo</vs-button
                >
              </div>
            </template>

            <div class="upload-img my-5" v-if="!logo">
              <input
                type="file"
                class="hidden"
                ref="uploadImgInput"
                @change="updateCurrImg"
                accept="image/*"
              />
              <vs-button
                type="border"
                class="btn-blue-border"
                @click="$refs.uploadImgInput.click()"
                >Upload Photo
              </vs-button>
            </div>
          </div>
          <!-- Room -->
          <div class="vx-col w-full mt-5">
            <div class="select-wrapper">
              <div class="vs-component is-label-placeholder">
                <!-- <label class="vs-input--label">Room</label> -->
                <v-select
                  :options="roomOptions"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="roomFilter"
                  class="mb-4 md:mb-0"
                  data-vv-as="Room"
                >
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('roomFilter')"
                    >{{ errors.first("roomFilter") }}</span
                  >
                  <template #header>
                    <label for class="label">Room</label>
                  </template>
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <vs-icon icon="arrow_drop_down"></vs-icon>
                    </span>
                  </template>
                </v-select>
              </div>
            </div>
          </div>

          <vs-input
            label-placeholder="Child's Full Name"
            v-model="fullName"
            class="mt-5 w-full"
            name="fullName"
            v-validate="'required'"
            data-vv-as="Child Name"
          />
          <span class="text-danger text-sm" v-show="errors.has('fullName')">{{
            errors.first("fullName")
          }}</span>

          <!-- Age Group -->
          <div class="vx-col w-full mt-5">
            <div class="select-wrapper">
              <div class="vs-component is-label-placeholder">
                <!-- <label class="vs-input--label">Age Group</label> -->
                <v-select
                  :options="ageOptions"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="ageFilter"
                  class="mb-4 md:mb-0"
                  data-vv-as="Age Group"
                >
                  <template #header>
                    <label for class="label">Age Group</label>
                  </template>
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <vs-icon icon="arrow_drop_down"></vs-icon>
                    </span>
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <!--Status-->
          <div class="w-full mt-5">
            <label class="vs-input--label">Account Status</label>
            <vs-switch v-model="status" />
            {{ status ? "Active" : "Inactive" }}
          </div>
          <!-- Parent Group -->
          <div class="vx-col w-full mt-5" v-if="selectedParent.length">
            <ul>
              <li
                class="mb-2"
                v-for="(part, index) in selectedParent"
                v-bind:key="index"
              >
                {{ part.label }}
                <span v-if="part.isPrimary">( Primary Parent / Guardian )</span>
                <span v-else>
                  <a
                    href="javascript:void(0);"
                    @click="setPrimaryParent(index)"
                    class="text-sm underline"
                    >Set as Primary Parent / Guardian</a
                  >
                </span>
              </li>
            </ul>
          </div>
          <div class="vx-col w-full mt-5" v-show="isShowParent">
            <div class="select-wrapper">
              <div class="vs-component is-label-placeholder">
                <!-- <label class="vs-input--label">Parent</label> -->
                <v-select
                  :options="parentOptions"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="parentFilter"
                  class="mb-4 md:mb-0 parent-select"
                  @input="setSelected"
                  data-vv-as="Parent"
                >
                  <template #header>
                    <label for class="label">Parent</label>
                  </template>
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <vs-icon icon="arrow_drop_down"></vs-icon>
                    </span>
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <vs-button
            v-if="!isShowParent"
            class="mt-5 mb-md-0 mb-2"
            align="right"
            @click="addNewParent"
            >Add Additional Parent / Guardian
          </vs-button>
        </div>

        <div
          class="flex flex-wrap items-center py-6 w-full md:w-1/2 justify-end"
        >
          <vs-button color="danger" @click="cancelBtn">Cancel</vs-button>
          <vs-button class="ml-8" @click="submitData" :disabled="!isFormValid"
            >Save</vs-button
          >
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import _ from "lodash";

export default {
  data() {
    return {
      dataId: null,
      fullName: "",
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
      roomOptions: [],
      ageOptions: [],
      parentOptions: [],
      logo: null,
      image: null,
      roomFilter: { label: "Select room", value: "" },
      ageFilter: { label: "Select age group", value: "" },
      parentFilter: { label: "Add New Parent / Guardian", value: "add" },
      selectedParent: [],
      selectedParentIndex: 0,
      isShowParent: true,
      customError: "",
      status: true,
      learningCenterId: ""
    };
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    centerId: {
      type: String,
      default: ""
    }
  },
  computed: {
    isFormValid() {
      return (
        !this.errors.any() &&
        this.roomFilter.value != "" &&
        this.fullName &&
        this.ageFilter.value != "" &&
        this.image &&
        this.parentFilter.value != ""
      ); 
    },
    director() {
      return this.$store.state.AppActiveUser;
    }
  },

  methods: {
    ...mapActions("center", [
      "updateDirectorChild",
      "addDirectorChild",
      "imageUpload",
      "getDirectorAllCountries",
      "getAgeGroup",
      "getDirectorRooms",
      "getDirectorParentList",
      "getLearningCenterRooms",
      "setTempChildData",
      "unSetTempChildData",
      "getParentByLearningCenterId"
    ]),
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        this.image = input.target.files[0];
        var reader = new FileReader();
        reader.onload = e => {
          this.logo = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },
    async submitData() {
      await this.$validator.validateAll().then(async result => {
        if (result) {
          let data = new FormData();
          data.append("id", this.dataId);
          data.append("fullName", this.fullName);
          data.append("room", this.roomFilter.value);
          data.append("parent", JSON.stringify(this.selectedParent));
          data.append("age", this.ageFilter.value);
          data.append("status", this.status);
          if ("undefined" != typeof this.$route.params.id) {
            data.append("learningCenter", this.$route.params.id);
          }

          data.append("logo", this.image);

          this.customError = "";
          if (this.dataId !== null) {
            this.$vs.loading();
            await this.updateDirectorChild(data)
              .then(res => {
                if (res.status === 200) {
                  this.$vs.notify({
                    title: "Success",
                    text: "Child Updated Successfully",
                    iconPack: "feather",
                    icon: "icon-alert-circle",
                    color: "success"
                  });
                  data.country = this.countryFilter.value;
                  this.$emit("refreshData", true);
                }
                this.$vs.loading.close();
              })
              .catch(err => {
                this.$vs.loading.close();
                // console.error(err)
              });
          } else {
            if (this.logo == null) {
              this.customError = "Image field is required.";
              return;
            }
            this.$vs.loading();
            await this.addDirectorChild(data)
              .then(res => {
                if (res.status === 200) {
                  this.$vs.loading.close();
                  this.$emit("refreshData", true);
                  this.$vs.notify({
                    title: "Success",
                    text: "Child added Successfully",
                    iconPack: "feather",
                    icon: "icon-alert-circle",
                    color: "success"
                  });
                }
              })
              .catch(err => {
                this.$vs.loading.close();
                if (err.response.status === "422") {
                  this.$vs.notify({
                    title: "Failure",
                    text: err.response.data.message,
                    iconPack: "feather",
                    icon: "icon-alert-circle",
                    color: "danger"
                  });
                }
                this.$vs.notify({
                  title: "Failure",
                  text: "Failed to add child",
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "danger"
                });
              });
          }
          this.$router.push({
            name: "director-learning-centre-view-list",
            params: { id: this.$route.params.id, type: "children" }
          });
        }
      });
    },

    async setSelected(event) {
      let self = this;

      if ("" == event.value) {
        const data = {
          room: this.roomFilter,
          fullName: this.fullName,
          age: this.ageFilter,
          logo: this.logo,
          image:this.image
        };

        await this.setTempChildData(data);

        this.$router.push({
          name: "director-parent-add",
          params: { id: this.learningCenterId }
        });
      } else {
        if (0 == this.selectedParent.length) {
          this.selectedParent.push({
            label: this.parentFilter.label,
            parentId: this.parentFilter._id,
            isPrimary: true
          });
        } else {
          this.selectedParent.push({
            label: this.parentFilter.label,
            parentId: this.parentFilter._id,
            isPrimary: false
          });
        }

        this.selectedParent = this.selectedParent.map(function(elem, index) {
          if (index == self.selectedParentIndex) {
            elem.isPrimary = true;
          } else {
            elem.isPrimary = false;
          }
          return elem;
        });
        this.isShowParent = false;
      }
    },
    addNewParent() {
      this.isShowParent = this.isShowParent ? false : true;
      if (0 == this.selectedParent.length) {
        this.isShowParent = true;
      }
    },
    setPrimaryParent(index) {
      let self = this;
      this.selectedParentIndex = index;
      this.selectedParent = this.selectedParent.map(function(elem, i) {
        if (i == self.selectedParentIndex) {
          elem.isPrimary = true;
        } else {
          elem.isPrimary = false;
        }
        return elem;
      });
      // this.selectedParent[index].parent = true;
    },
    showParentsList: function() {},
    cancelBtn() {
      this.$router.push({
        name: "director-learning-centre-view-list",
        params: { type: "children", id: this.$route.params.id }
      });
    }
  },
  watch: {
    roomFilter(data) {
    console.log(data,"watchedddddd")
      if (data.ageGroupId) {
      console.log(data.ageGroupId,"data.ageGroupId",this.ageOptions)
        this.ageFilter = _.find(this.ageOptions, function(o) {
        console.log(o,"ooooo");
          return o.value === data.ageGroupId;
        });
      }
    }
  },
 async created() {
    this.learningCenterId = this.$route.params.id;
    await this.getAgeGroup()
      .then(res => {
        this.ageOptions = res;
      })
      .catch(err => {});
    await this.getParentByLearningCenterId(this.learningCenterId)
      .then(res => {
        this.parentOptions = res;
      })
      .catch(err => {});
    await this.getLearningCenterRooms((this.learningCenterId = this.$route.params.id))
      .then(res => {
        this.roomOptions = res.data.data;
        this.roomOptions = this.roomOptions.map(item => {
          return {
            value: item._id,
            label: item.name,
            ageGroupId: item.ageGroup ? item.ageGroup : ""
          };
        });
      })
      .catch(err => {});


    const tempChild = this.$store.state.center.tempChildData;
    if (tempChild) {
      this.logo = tempChild.logo;
      this.fullName = tempChild.fullName;
      this.roomFilter = await tempChild.room;
      this.ageFilter = await tempChild.age;
      this.image = tempChild.image;
      this.parentFilter = await tempChild.parent ? tempChild.parent: { label: "Add New Parent / Guardian", value: "add" };
    }
    this.unSetTempChildData();

    
  },
  components: {
    vSelect
    // VuePerfectScrollbar,
  }
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
